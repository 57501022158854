<template>
  <div class="login">
    <div class="shanxi_login" v-if="location == '陕西省'">
      <img class="shanxi_title" src="~/static/shanxi/logo (1).png" />
      <div class="login_content flex">
        <img src="~/static/login/canvas.png" alt="扫码下载官方APP" />
        <loginAll status="1" type="1"></loginAll>
      </div>
    </div>
    <div class="user_login" v-else>
      <div class="content">
        <div class="title">
          <h3>兰州市残疾人就业创业网络服务平台 | 用户登录</h3>
          <router-link to="/">返回首页</router-link>
        </div>
        <div class="login">
          <h1 class="name">个人用户登录/注册</h1>
          <div class="login_content flex">
            <img src="~/static/login/canvas.png" alt="扫码下载官方APP" />
            <loginAll status="1" type="1"></loginAll>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginAll from "@/components/login/loginAll";
export default {
  name: "login",
  components: {
    loginAll,
  },

  data() {
    return {
      dialogTableVisible: true,
      isHide: false,
      location: "",
    };
  },

  created() {
    this.location = localStorage.getItem("locationSite");
  },
  mounted() {},
};
</script>
<style lang="less" scoped>
.user_login {
  width: 100%;
  min-height: 100vh;
  background: url("~static/login/bg.png") center no-repeat;
  background-size: 100% 100%;
  .content {
    width: 1200px;
    margin: 0 auto;
    .title {
      display: flex;
      padding-top: 26px;
      h3 {
        flex: 1;
        font-size: 18px;
        color: white;
      }
      a {
        font-size: 18px;
        font-weight: bold;
        color: white;
      }
      a:hover {
        color: #333;
      }
    }
    .login {
      width: 792px;
      margin: 90px auto 0 auto;
      h1 {
        text-align: center;
        font-size: 28px;
        color: white;
        margin-bottom: 36px;
      }
    }
  }
}
.shanxi_login {
  background: url(~static/shanxi/loginbg.png) center center no-repeat;
  width: 100%;
  min-height: 100vh;
  background-size: 100% 100%;
  padding: 54px 0 50px 0;
  .shanxi_title {
    margin: 0 auto;
    display: inherit;
  }
  .login_content {
    width: 792px;
    margin: 70px auto 0 auto;
  }
}
</style>
